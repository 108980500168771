import algosdk from "algosdk";
import MyAlgoConnect from "@randlabs/myalgo-connect";

const mainnet = "https://algoexplorerapi.io";
const testnet = "https://node.testnet.algoexplorerapi.io";

const connection = new MyAlgoConnect({ timeout: 90000 });
connection.timeout = 90000;

const algodClient = new algosdk.Algodv2("", testnet, "");

export { connection, algodClient };
