import React, { Suspense } from "react";
import Defer from "../../components/Defer";
import FadeIn from "react-fade-in";
import { Loading } from "../../components/Loading";
import { SuspenseImg } from "../../components/SuspenseImg";
import { useImages } from "../utils/useImages";

export function NftImages() {
  const images = useImages(475, 525);

  if (!images) {
    return <></>;
  }
  return (
    <>
      <Defer chunkSize={15}>
        <FadeIn
          transitionDuration={200}
          delay={20}
          className="collection-container"
        >
          {/* <Box className="collection-container"> */}
          {images &&
            images.map((img, i) => (
              <Suspense key={i} fallback={<Loading />}>
                <SuspenseImg url={img} id={i} />
              </Suspense>
            ))}
          {/* </Box> */}
        </FadeIn>
      </Defer>
    </>
  );
}
