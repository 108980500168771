import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "../../components/Typography";

function ProductSmokingHero() {
  return (
    <Container
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        mt: 12,
        mb: 9,
      }}
    >
      <Button
        href="https://discord.gg/rQwYN2maph"
        target="_blank"
        sx={{
          border: "4px solid currentColor",
          borderRadius: 0,
          height: "auto",
          py: 2,
          px: 5,
        }}
      >
        <Typography
          variant="h4"
          component="span"
          sx={{ fontSize: { xs: "18px", sm: "20px", md: "36px" } }}
        >
          Join our discord server
        </Typography>
      </Button>
      <Typography variant="subtitle1" sx={{ my: 3 }}>
        Free NFT raffles. Fun conversation. It's cool.
      </Typography>
      <Box
        component="img"
        src="/assets/discord.svg"
        alt="buoy"
        sx={{ width: 60 }}
      />
    </Container>
  );
}

export default ProductSmokingHero;
