import React from "react";

export default function Defer({ chunkSize, children }) {
  const [renderedItemsCount, setRenderedItemsCount] = React.useState(chunkSize);

  const childrenArray = React.useMemo(
    () => React.Children.toArray(children),
    [children]
  );

  React.useEffect(() => {
    if (renderedItemsCount < childrenArray.length) {
      const id = window.requestIdleCallback(
        () => {
          setRenderedItemsCount(
            Math.min(renderedItemsCount + chunkSize, childrenArray.length)
          );
        },
        { timeout: 200 }
      );
      return () => window.cancelIdleCallback(id);
    }
  }, [renderedItemsCount, childrenArray.length, chunkSize]);

  return childrenArray.slice(0, renderedItemsCount);
}
