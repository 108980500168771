import * as React from "react";
import { Button } from "components";
import Typography from "../../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import { Link } from "react-router-dom";

const backgroundImage = "/assets/hero2.jpg";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#292929", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: "none" }} src={backgroundImage} alt="increase priority" />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        ALGOPARD NFT
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="body1"
        sx={{ mb: 2, mt: { xs: 4, sm: 10 }, fontSize: "18px" }}
      >
        969 unique NFT pieces by Algopard.com
      </Typography>
      <a style={{ textDecoration: "none" }} href="https://www.randgallery.com/collection/algopard/">
        <Button
          variant="contained"
          size="large"
          component="button"
          target="_blank"
          sx={{
            minWidth: 200,
            backgroundColor: "#3E416A",
            textAlign: "center",
            fontFamily: "Brutal Type Light",
            letterSpacing: "0.06em",
          }}
        > 
          JOIN THE PARDSQUAD 
          </Button>
          </a>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Discover the experience
      </Typography>
    </ProductHeroLayout>
  );
}
//if you want to change the link for Pardcubs button then copy the same setting here on to ProductCTA where the link is and turn into a tag