import { useState, useEffect } from "react";
const env = process.env.NODE_ENV;

const apiUrl =
  env === "development"
    ? process.env.REACT_APP_API_DEV
    : process.env.REACT_APP_API_PROD;

function loadImages(first, last) {
  const bgImages = [];
  for (let i = first; i <= last; i++) {
    bgImages.push(`${apiUrl}/webp/${i}.png.webp`);
  }
  return bgImages;
}

export function useImages(first, last) {
  const [images, setImages] = useState(false);

  useEffect(() => {
    const getData = () => {
      const loadedImages = loadImages(first, last);
      setImages(loadedImages);
    };
    getData();
  }, [first, last]);

  return images;
}
