import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../../components/AppBar";
import Toolbar from "../../components/Toolbar";
import { Button } from "components";
import Typography from "../../components/Typography";

const linkStyle = {
  fontSize: 16,
  color: "common.white",
  fontWeight: 500,
  letterSpacing: 0.5,
  ml: 3,
};

const sxType = {
  fontFamily: "Work Sans",
  fontWeight: 400,
  fontSize: "14px",
  color: "#fff",
  opacity: 0.85,
  letterSpacing: "0.02em",
  lineHeight: 1.25,
};

const sxLogoTypeHeader = {
  ...sxType,
  fontWeight: 600,
  fontSize: "12px",
  opacity: 0.65,
  letterSpacing: "0.1em",
};

function shortWalletAddress(address) {
  return (
    address.substring(0, 5) + "..." + address.substring(address.length - 5)
  );
}

export default function Nav({ algoWallet, setAlgoWallet, connectToMyAlgo }) {
  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#27272C",
        }}
      >
        <Toolbar
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          sx={{
            justifyContent: "space-between",
            background:
              "linear-gradient(270.16deg, #0E0E10 -0.05%, rgba(23, 23, 27, 0.65) 38.08%, rgba(255, 255, 255, 0.1) 100%)",
          }}
        >
          <Link
            variant="h6"
            sx={{
              ...linkStyle,
              ml: 0,
              display: "flex",
              alignItems: "center",
            }}
            underline="none"
            color="inherit"
            href="#"
          >
            <Box
              component="img"
              src="/assets/marketplace_logo.png"
              alt="algopard marketplace logo"
              sx={{ height: 70 }}
            />
            {/* <div style={{ flexFlow: "column wrap", textAlign: "center" }}>
              <Typography sx={sxLogoType}>ALGOPARD</Typography>
              <Typography sx={sxLogoTypeSub}>Marketplace</Typography>
            </div> */}
          </Link>
          {algoWallet.name ? (
            <>
              <Button
                onClick={() => setAlgoWallet({ address: "", name: "" })}
                variant="contained"
                component="a"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: 160,
                  maxHeight: "70px",
                  backgroundColor: "#353536",
                  textAlign: "center",
                  borderRadius: "3px",
                }}
              >
                <Typography sx={sxLogoTypeHeader}>Switch Account:</Typography>
                <Typography sx={sxType}>
                  {shortWalletAddress(algoWallet.address)}
                </Typography>
              </Button>
            </>
          ) : (
            <Button
              onClick={() => connectToMyAlgo(setAlgoWallet)}
              variant="contained"
              size="medium"
              component="a"
              sx={{
                mx: 1,
                minWidth: "5rem",
                backgroundColor: "#F32E53",
                textAlign: "center",
                borderRadius: "3px",
                fontFamily: "Work Sans",
                fontWeight: 500,
              }}
            >
              Connect Wallet
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
