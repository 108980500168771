import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import withRoot from "../withRoot";
import Nav from "./../marketplace/views/Nav";
import Snackbar from "./../components/Snackbar";
import SnackbarError from "./../components/SnackbarError";
import { connectToMyAlgo } from "./../marketplace/utils/algoConnect";
import { checkAuth } from "./auth/checkAuth";
import { updateShuffle } from "marketplace/utils/api";
import { Dashboard } from "./Dashboard";

// const price = 1000000; // 1 ALGO

function Admin({ socket }) {
  const [algoWallet, setAlgoWallet] = useState({ address: "", name: "" });
  const [snack, setSnack] = useState({ open: false, message: "" });
  const [user, setUser] = useState(false);
  const [shuffle, setShuffle] = useState(false);

  // "U5RDEH7AEDTAGDG2FZ4ODWZS27MSGW4BLUJPRB7B76UQHSNKM56MREWM2A"
  useEffect(() => {
    const fetchData = async () => {
      const auth = await checkAuth(algoWallet.address);
      setUser(auth);
    };
    if (algoWallet.address) {
      fetchData().catch(console.error);
    }
  }, [algoWallet.address]);

  useEffect(() => {
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
    socket.on("shuffle-update", (shuffle) => {
      console.log(shuffle, "shuffle data");
      setShuffle(shuffle);
    });
  }, [socket]);

  useEffect(() => {
    if (algoWallet.address) {
      socket.emit("user", {
        creatorAddr: algoWallet.address,
        id: socket.id,
      });
    }
  }, [algoWallet.address, socket]);

  async function handleUpdateSubmit(addr, fields, token) {
    const res = await updateShuffle(addr, fields, token);
    console.log(res, "update shuffle res");
  }
  console.log(shuffle, "SHUFFLE STATE");

  return (
    <>
      <Nav
        algoWallet={algoWallet}
        setAlgoWallet={setAlgoWallet}
        connectToMyAlgo={connectToMyAlgo}
      />
      <Box
        component="section"
        sx={{
          display: "flex",
          bgcolor: "#39393E",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        {/* <Sidebar /> */}
        <Box
          component="section"
          sx={{
            display: "flex",
            pt: "70px",
            pb: "52px",
            width: "100%",
          }}
        >
          {user ? (
            <Dashboard
              shuffle={shuffle}
              algoWallet={algoWallet}
              user={user}
              handleUpdateSubmit={handleUpdateSubmit}
            />
          ) : (
            ""
          )}
        </Box>
        <Snackbar
          open={snack.open}
          closeFunc={() => {
            clearTimeout(snack.timer);
            setSnack({});
          }}
          message={snack.message}
        />
        <SnackbarError
          open={snack.openError}
          closeFunc={() => {
            clearTimeout(snack.timer);
            setSnack({});
          }}
          message={snack.message}
        />
      </Box>
    </>
  );
}

export default withRoot(Admin);
