import * as React from "react";
import Home from "./Home";
import Nav from "./views/Nav";
import Game from "./views/Game";
import withRoot from "../withRoot";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Nav />
      <Routes>
        <Route path="/game" element={<Game />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </>
  );
}

export default withRoot(App);
