import { connection } from "./connections";

export async function connectToMyAlgo(cb) {
  try {
    const options = { openManager: true };
    const account = await connection.connect(options);
    console.log(account, "ACCOUNT OBJ USER");

    cb({ ...account[0], connection });
  } catch (err) {
    console.error(err);
  }
}
