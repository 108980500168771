import React, { useState } from "react";
import {
  Box,
  Typography,
  Switch,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import TimePicker from "@mui/lab/TimePicker";
import { setAvailableAssets, scheduleShuffle } from "./../marketplace/utils/api";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const row = {
  display: "flex",
  flexFlow: "row nowrap",
};

const sxTypeBold = {
  fontFamily: "Work Sans",
  fontWeight: 500,
  fontSize: "14px",
  color: "#fff",
  opacity: 0.8,
  lineHeight: "20px",
  textAlign: "left",
};

const sxType = {
  fontFamily: "Work Sans",
  fontWeight: 400,
  fontSize: "14px",
  color: "#fff",
  opacity: 0.95,
  lineHeight: "20px",
  textAlign: "left",
};

const sxTypeData = {
  ...sxType,
  fontWeight: 600,
  // opacity: 0.9,
};

function shortWalletAddress(address) {
  return address.substring(0, 5) + "..." + address.substring(address.length - 5);
}

function shuffleStatus(isOpen) {
  return isOpen ? "OPEN" : "CLOSED";
}

export function Dashboard({ shuffle, user, algoWallet, handleUpdateSubmit }) {
  const [checked, setChecked] = useState(shuffle.isOpen);
  const [form, setForm] = useState("");
  const [price, setPrice] = useState("");
  const [time, setTime] = useState();
  const [weekDay, setWeekDay] = useState("");
  const [closeTime, setCloseTime] = useState();
  const [closeWeekDay, setCloseWeekDay] = useState("");

  const handleChange = (value) => setForm(value);

  const handleTimeChange = (value) => setTime(value);

  const handleSwitchChange = (e) => {
    setChecked(e.target.checked);
    handleUpdateSubmit(algoWallet.address, { isOpen: e.target.checked }, user.token);
  };

  const switchSx = {
    "& .MuiSwitch-thumb": {
      backgroundColor: checked ? "#39E290" : "#fff",
    },
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: checked ? "#9FFED0" : "#7D7D7D",
        },
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: checked ? "#9FFED0" : "#7D7D7D",
    },
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexFlow: "column wrap",
          justifyContent: "start",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <LocalizationProvider dateAdapter={DateAdapter}>
          <Box
            sx={{
              width: "100%",

              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              my: 4,
            }}
          >
            <Box sx={{ ...sxTypeBold, ...row, mx: 3 }}>
              CREATOR ACCOUNT:{" "}
              <Typography sx={{ ...sxTypeData, mx: 2 }}>
                {shortWalletAddress(algoWallet.address)}
              </Typography>
            </Box>
            <Box sx={{ ...sxTypeBold, ...row, mx: 3 }}>
              TOTAL ASSETS REMAINING:{" "}
              <Typography sx={{ ...sxTypeData, mx: 2 }}>feature coming soon</Typography>
            </Box>
          </Box>

          {/* SHUFFLE CONTROLS SECTION */}
          <Box
            sx={{
              width: "100%",
              maxWidth: "600px",
              px: "15px",
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#fff",
              mt: 4,
            }}
          >
            {/* Shuffle status, title + toggle switch */}
            <Box
              sx={{
                display: "flex",
                flexFlow: "column wrap",
                justifyContent: "start",
                alignItems: "center",
                color: "#fff",
              }}
            >
              <Typography
                sx={{
                  ...sxTypeBold,
                  fontSize: "23px",
                  opacity: 0.85,
                  lineHeight: "18px",
                }}
              >
                ALGOPARD SHUFFLE
              </Typography>
              <Box
                sx={{
                  ...row,
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={sxType}>
                  Status: <span style={{ fontWeight: 600 }}>{shuffleStatus(shuffle.isOpen)}</span>
                </Typography>
                <Switch checked={checked} onChange={handleSwitchChange} sx={switchSx} />
              </Box>
            </Box>

            {/* shuffle assets info */}
            <Box
              sx={{
                display: "flex",
                flexFlow: "column wrap",
                justifyContent: "start",
                alignItems: "start",
                color: "#fff",
              }}
            >
              <Box sx={{ ...sxTypeBold, ...row }}>
                PRICE:
                <span style={{ ...sxTypeData, marginLeft: "6px" }}>
                  {shuffle.entryPrice + " A"}
                </span>
              </Box>
              <Box sx={{ ...sxTypeBold, ...row }}>
                SHUFFLE SIZE:
                <span style={{ ...sxTypeData, marginLeft: "6px" }}>{shuffle.totalAssets}</span>
              </Box>
              <Box sx={{ ...sxTypeBold, ...row }}>
                ASSETS REMAINING:
                <span style={{ ...sxTypeData, marginLeft: "6px" }}>
                  {shuffle.availableAssets.length}
                </span>
              </Box>
            </Box>
          </Box>
          {/* EDIT SHUFFLE DETAILS */}
          <Box
            sx={{
              // width: "100%",
              px: "15px",
              display: "flex",
              flexFlow: "column wrap",
              justifyContent: "start",
              alignItems: "start",
              color: "#fff",
              mt: 6,
            }}
          >
            <Typography sx={{ ...sxTypeBold }}>SHUFFLE DETAILS</Typography>
            <Typography sx={{ ...sxType }}>
              Set the number of assets to include and the entry price for the shuffle.
            </Typography>
            <Box
              sx={{
                ...row,
                mt: 2,
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <TextField
                id="filled-basic"
                label="# Assets"
                variant="filled"
                onChange={(e) => handleChange(e.target.value)}
                value={form}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "#2A2A30",
                    color: "#fff",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#858585",
                  },
                  input: { color: "#fff" },
                  width: "48%",
                }}
              />

              <TextField
                id="filled-basic"
                label="Price"
                variant="filled"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "#2A2A30",
                    color: "#fff",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#858585",
                  },
                  input: { color: "#fff" },
                  width: "48%",
                }}
              />
            </Box>
            <Button
              align="center"
              variant="contained"
              size="large"
              disabled={!form || !price}
              sx={{
                width: "100%",
                height: "42px",
                backgroundColor: "#1AD47A",
                mt: 2,
              }}
              onClick={async () => {
                const res = await setAvailableAssets(algoWallet.address, form, price, user.token);
                console.log(res, "on setavailassets");
              }}
            >
              SUBMIT
            </Button>

            {/* SCHEDULE SHUFFLE */}
            <Box
              sx={{
                // width: "100%",
                display: "flex",
                flexFlow: "column wrap",
                justifyContent: "start",
                alignItems: "start",
                color: "#fff",
                mt: 6,
              }}
            >
              <Typography sx={{ ...sxTypeBold, mb: 1 }}>SCHEDULE</Typography>
              <Box
                sx={{
                  px: "15px",
                  py: "13px",
                  display: "flex",
                  flexFlow: "column wrap",
                  justifyContent: "start",
                  alignItems: "start",
                  color: "#fff",
                  mb: 3,
                  backgroundColor: "#414145",
                  borderRadius: "3px",
                }}
              >
                <Typography sx={{ ...sxTypeBold, opacity: 0.9, letterSpacing: "0.01em" }}>
                  Upcoming
                </Typography>
                <Box
                  sx={{
                    ...row,
                    my: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {shuffle.schedule && shuffle.schedule.open ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexFlow: "column wrap",
                          justifyContent: "center",
                          alignItems: "start",
                          mx: 3,
                        }}
                      >
                        <Typography sx={{ ...sxTypeBold, fontSize: "12px", opacity: 0.8 }}>
                          OPEN
                        </Typography>
                        <Typography sx={{ ...sxTypeData }}>{shuffle.schedule.open.day}</Typography>
                        <Typography sx={{ ...sxTypeData }}>{shuffle.schedule.open.time}</Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexFlow: "column wrap",
                          justifyContent: "center",
                          alignItems: "start",
                          mx: 3,
                        }}
                      >
                        <Typography sx={{ ...sxTypeBold, fontSize: "12px", opacity: 0.8 }}>
                          CLOSE
                        </Typography>
                        <Typography sx={{ ...sxTypeData }}>{shuffle.schedule.close.day}</Typography>
                        <Typography sx={{ ...sxTypeData }}>
                          {shuffle.schedule.close.time}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Typography sx={{ ...sxType }}>Nothing scheduled yet!</Typography>
                  )}
                </Box>
              </Box>

              <Typography sx={{ ...sxTypeBold, mb: 1 }}>Set Schedule</Typography>
              <Box
                sx={{
                  ...row,
                  mt: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TimePicker
                  label="Time"
                  value={time}
                  onChange={handleTimeChange}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        "& .MuiFormLabel-filled": {
                          color: "#fff",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "admin.main",
                        },
                        "& .MuiOutlinedInput-input": {
                          color: "#fff",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#fff",
                        },
                        width: "48%",
                      }}
                      {...params}
                    />
                  )}
                />
                <FormControl variant="filled" sx={{ width: "48%" }}>
                  <InputLabel id="demo-simple-select-filled-label">Day</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={weekDay}
                    onChange={(e) => setWeekDay(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={0}>Sunday</MenuItem>
                    <MenuItem value={1}>Monday</MenuItem>
                    <MenuItem value={2}>Tuesday</MenuItem>
                    <MenuItem value={3}>Wednesday</MenuItem> <MenuItem value={4}>Thursday</MenuItem>{" "}
                    <MenuItem value={5}>Friday</MenuItem> <MenuItem value={6}>Saturday</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  ...row,
                  mt: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TimePicker
                  label="Close Time"
                  value={closeTime}
                  onChange={(value) => setCloseTime(value)}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        "& .MuiFormLabel-filled": {
                          color: "#fff",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "admin.main",
                        },
                        "& .MuiOutlinedInput-input": {
                          color: "#fff",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#fff",
                        },
                        width: "48%",
                      }}
                      {...params}
                    />
                  )}
                />
                <FormControl variant="filled" sx={{ width: "48%" }}>
                  <InputLabel id="demo-simple-select-filled-label">Day</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={closeWeekDay}
                    onChange={(e) => setCloseWeekDay(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={0}>Sunday</MenuItem>
                    <MenuItem value={1}>Monday</MenuItem>
                    <MenuItem value={2}>Tuesday</MenuItem>
                    <MenuItem value={3}>Wednesday</MenuItem>
                    <MenuItem value={4}>Thursday</MenuItem>
                    <MenuItem value={5}>Friday</MenuItem>
                    <MenuItem value={6}>Saturday</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Button
                align="center"
                variant="contained"
                size="large"
                disabled={!time || !closeTime || !weekDay || !closeWeekDay}
                sx={{
                  width: "100%",
                  height: "42px",
                  backgroundColor: "#1AD47A",
                  mt: 2,
                }}
                onClick={async () => {
                  const res = await scheduleShuffle(
                    algoWallet.address,
                    time,
                    closeTime,
                    weekDay,
                    closeWeekDay,
                    user.token
                  );
                  console.log(res, "on schedule");
                }}
              >
                SUBMIT
              </Button>
            </Box>
          </Box>
        </LocalizationProvider>
      </Box>
    </>
  );
}
