import React from "react";
import { Box } from "@mui/material";

// const imgCache = {
//   __cache: {},
//   read(src) {
//     if (!this.__cache[src]) {
//       this.__cache[src] = new Promise((resolve) => {
//         const img = new Image();
//         img.onload = () => {
//           this.__cache[src] = true;
//           resolve(this.__cache[src]);
//         };
//         img.src = src;
//       }).then((img) => {
//         this.__cache[src] = true;
//       });
//     }
//     if (this.__cache[src] instanceof Promise) {
//       throw this.__cache[src];
//     }
//     return this.__cache[src];
//   },
// };

export const SuspenseImg = ({ url, id, ...rest }) => {
  return (
    <Box
      id={id}
      className="suspense-img"
      sx={{
        transition: "opacity 0.5s ease-in",
        backgroundImage: `url(${url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        mx: "1px",
        my: "1px",
        borderRadius: "7px",
        filter: "blur(1px)",
      }}
    />
  );
};
