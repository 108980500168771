import React from "react";
import { InfoBox } from "components";
import { Typography, Box } from "@mui/material";
import { ShuffleButton } from "components";
import algoSign from "./../images/algo.png";

const sxType = {
  fontFamily: "Work Sans",
  fontWeight: 400,
  fontSize: "16px",
  color: "#fff",
  opacity: 0.96,
  lineHeight: "19px",
  mb: 1,
};

const sxTypeInfoHeader = {
  ...sxType,
  fontWeight: 600,
  fontSize: "14px",
  opacity: 0.45,
};

const sxTypeInfo = {
  ...sxType,
  fontWeight: 600,
  fontSize: "26px",
  letterSpacing: "0.02em",
  mb: 0,
};

export function LiveShuffle({ shuffle, disableButton, onShuffleClick }) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column wrap",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#1c1c21",
          borderRadius: "7px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          py: 5,
          px: 2,
          width: "90vw",
          minWidth: "280px",
          maxWidth: "500px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1c1c21",
            borderRadius: "7px",
            py: 2,
            // px: 5,
          }}
        >
          <InfoBox className="info-box">
            <Typography sx={sxTypeInfoHeader}># LEFT</Typography>
            <Typography sx={sxTypeInfo}>
              {shuffle._id && shuffle.availableAssets.length}
            </Typography>
          </InfoBox>
          <InfoBox>
            <Typography sx={sxTypeInfoHeader}>PRICE</Typography>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={sxTypeInfo}>
                {shuffle._id && shuffle.entryPrice}
              </Typography>
              <Box
                component="img"
                src={algoSign}
                alt="algo price sign"
                sx={{ height: "24px", width: "24px", ml: 1 }}
              />
            </Box>
          </InfoBox>
        </Box>
        <ShuffleButton
          onClick={() => onShuffleClick()}
          disabled={shuffle.availableAssets.length > 0 ? disableButton : true}
        />
      </Box>
    </>
  );
}
