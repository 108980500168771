import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import banner from "./images/Game-Banner.svg";
import { Link } from "react-router-dom";

export function Banner({ closeFunc }) {
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          display: "flex",
          bottom: 0,
          right: 0,
          //   left: "auto",
          //   ml: "auto",
          alignSelf: "flex-end",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor: "#fff",
            alignSelf: "flex-start",
            opacity: 0.8,
          }}
          onClick={() => closeFunc()}
        >
          <CloseIcon />
        </IconButton>
        <Link
          style={{
            textDecoration: "none",
            width: "50%",
            minWidth: "280px",
            position: "sticky",
            bottom: 0,
            right: 0,
            alignSelf: "flex-end",
          }}
          to="/game"
        >
          <Box
            component="img"
            src={banner}
            sx={{
              width: "100%",
            }}
          />
        </Link>
      </Box>
    </>
  );
}
