import * as React from "react";
import { Box, Typography, Button } from "@mui/material";
import gameBanner from "./../images/Game-Banner.jpg";
import goPard from "./../images/gopard.png";
import giraffe from "./../images/giraffe-hunt.png";
import owl from "./../images/owl-hunt.png";
import monkey from "./../images/monkey-hunt.png";
import deer from "./../images/deer-hunt.png";
import rabbit from "./../images/rabbit-hunt.png";

const sxContainer = {
  pt: "70px",
  width: "100%",
  height: "100%",
  background: "linear-gradient(231.79deg, #4F4F4F 0%, #313132 34.41%, #17171A 92.42%)",
};

const sxRow = {
  display: "flex",
  flexFlow: "row wrap",
  width: "100%",
};

const sxColumn = {
  display: "flex",
  flexFlow: "column wrap",
  alignItems: "center",
  justifyContent: "center",
  mx: 3,
  my: 2,
};

const sxTypeH1 = {
  fontFamily: "Gisbon",
  fontSize: "24px",
  color: "#fff",
  letterSpacing: "0.1em",
};

const sxTypeH2 = {
  fontFamily: "Brutal Type Extra-Bold",
  fontSize: "16px",
  color: "#fff",
  opacity: 0.85,
  letterSpacing: "0.025em",
  textAlign: "left",
  mb: 1,
};

const sxTypeMedium = {
  fontFamily: "Brutal Type Medium",
  fontSize: "18px",
  opacity: 0.95,
  color: "#fff",
  letterSpacing: "0.03em",
};

const sxLight = {
  fontFamily: "Brutal Type Light",
  fontSize: "15px",
  color: "#fff",
  opacity: 0.9,
  maxWidth: "500px",
};

const sxType = {
  fontFamily: "Brutal Type Regular",
  fontSize: "16px",
  color: "#fff",
  opacity: 0.85,
  maxWidth: "600px",
};

export default function Game() {
  return (
    <>
      <Box component="section" sx={sxContainer}>
        {/* IMAGE AND OVERVIEW HERO */}
        <Box sx={sxRow}>
          <Box
            component="img"
            src={gameBanner}
            sx={{
              flex: 1,
              width: "100%",
              // height: "100%",
            }}
          />
          <Box
            sx={{
              width: "100%",
              // height: "400px",
              display: "flex",
              flex: 1,
              minWidth: "180px",
              flexFlow: "column wrap",
              justifyContent: "center",
              alignItems: "start",
              px: 3,
            }}
          >
            {/* prettier-ignore */}
            <Typography sx={{...sxTypeH1, mt: 3, mb: 3}}>TROPICAL FOREST  {'\u00A0'}HUNTING GAME</Typography>
            <Typography sx={sxType}>
              Tropical Forest Hunting Game is a discord game (desktop and mobile compatible) for
              Algopard server which utilizes AlgoLeagues Coin to participate in a Play-to-Earn game.
              <br />
              <br />
              Players can earn additional prizes each week on top of ALCs. Specific attacks that are
              determined by the Admins such as: first player to start the attack, last player to
              attack, and the player that attacks the most will be eligible for these prizes.
            </Typography>

            <Box
              sx={{
                width: "100%",
                maxWidth: "500px",
                display: "flex",
                flexFlow: "column wrap",
                justifyContent: "start",
                mt: 3,
                mb: 3,
              }}
            >
              <Typography sx={sxTypeH2}>PRIZES</Typography>
              <Box
                sx={{
                  ...sxRow,
                  maxWidth: "500px",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ ...sxTypeMedium, mr: 1 }}>NFTS</Typography>
                <Typography sx={{ ...sxTypeMedium, mx: 1 }}>YLDY</Typography>
                <Typography sx={{ ...sxTypeMedium, mx: 1 }}>SOCKS</Typography>
                <Typography sx={{ ...sxTypeMedium, mx: 1 }}>ACORN</Typography>
                <Typography sx={{ ...sxTypeMedium, mx: 1 }}>AKC</Typography>
                <Typography
                  sx={{ ...sxTypeMedium, fontSize: "16px", color: "#2DF3F2", alignSelf: "end" }}
                >
                  ...and more!
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* HOW TO PLAY SECTION */}
        <Box sx={sxRow}>
          <Box
            sx={{
              flex: 2,
              width: "60%",
              display: "flex",
              flexFlow: "column nowrap",
              justifyContent: "center",
              alignItems: "center",
              mt: 3,
              px: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                width: "100%",
                mt: 3,
              }}
            >
              <Typography sx={{ ...sxTypeH2, fontSize: "20px", opacity: 0.9 }}>
                HOW TO PLAY
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  justifyContent: "flex-start",
                  alignItems: "start",
                  width: "100%",
                  maxWidth: "611px",
                  height: "auto",
                  backgroundColor: "#3E3E40",
                  px: "18px",
                  py: "16px",
                  borderRadius: "3px",
                  lineHeight: "14px",
                }}
              >
                <Typography sx={{ ...sxTypeMedium, mb: "8px" }}>Prerequisites</Typography>
                <Typography sx={{ ...sxType, mb: "12px" }}>
                  • Must be opt-in AlgoLeagues coin ASA: 445905873
                </Typography>
                <Typography sx={{ ...sxType, mb: "12px" }}>
                  • Must hold at least ONE Algopard NFT created by: PARDGF6...IIFKH4
                </Typography>
                <Typography sx={{ ...sxType }}>
                  • Go to{" "}
                  <span
                    style={{
                      backgroundColor: "#6D38A3",
                      padding: "2px 4px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    #tropical-forest-hunting
                  </span>{" "}
                  channel and type{" "}
                  <span
                    style={{
                      backgroundColor: "#272727",
                      padding: "2px 4px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    /register &#60;wallet address&#62;
                  </span>{" "}
                  to register your wallet.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "column wrap",
                  width: "100%",
                  mt: 2,
                  px: "18px",
                  py: "16px",
                }}
              >
                <Typography sx={{ ...sxTypeMedium, mb: "8px" }}>Gameplay</Typography>
                <Typography sx={{ ...sxType }}>
                  1. Wait for prey to appear in the tropical forest (every 6 hours). Certain prey
                  are more rewarding than others.{" "}
                  <span style={{ color: "#A0E8A3", fontSize: "14px" }}>Hint is in the banner!</span>
                </Typography>
                <Typography sx={{ ...sxLight, mb: "12px", mt: "6px" }}>
                  The first /gopard attack command will randomly generate one prey that lasts until
                  it’s hunted.
                </Typography>
                <Typography sx={{ ...sxType, mb: "12px" }}>
                  2. Use{" "}
                  <span
                    style={{
                      backgroundColor: "#343434",
                      padding: "2px 4px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    /gopard attack
                  </span>{" "}
                  command to attack the prey (you can hunt multiple times-slow mode on!).
                </Typography>
                <Typography sx={{ ...sxType }}>
                  3. After hunting the prey, rewards will be distributed and{" "}
                  <span
                    style={{
                      backgroundColor: "#6D38A3",
                      padding: "2px 4px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    #tropical-forest-hunting
                  </span>{" "}
                  will be on cooldown. (Every 6 hours)
                </Typography>
              </Box>
              <Typography sx={{ ...sxTypeMedium, fontSize: "16px", mt: 3, mb: "8px" }}>
                Each Pard you own adds 10 skins on average (range 7-12 skins per pard) to your attacks
              </Typography>
              <Typography sx={{ ...sxType, mb: 4 }}>
                Example: if you have 5 Pards in your wallet, each attack will get you 50 skins on average. There
                are 5 difficulty levels for five prey, some are hard to catch so you could end up
                licking your paws with less skins!
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              // flex: 1,
              width: "40%",
              minWidth: "230px",
              position: "relative",
              alignItems: "end",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={goPard}
              sx={{
                width: "100%",
                marginLeft: "auto",
              }}
            />
          </Box>
        </Box>

        {/* POINT DISTRO SECTION */}
        <Box
          sx={{
            display: "flex",
            flexFlow: "column wrap",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#393939",
            py: 2,
          }}
        >
          <Typography sx={{ ...sxTypeH2, fontSize: "20px", opacity: 0.9, my: 2 }}>
            POINT DISTRIBUTION
          </Typography>

          <Box sx={{ ...sxRow, justifyContent: "center", alignItems: "center", px: 3 }}>
            <Box sx={{ ...sxColumn, maxWidth: "150px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  alignItems: "end",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ ...sxTypeMedium, fontSize: "16px", mr: 2, opacity: 0.8, textAlign: "left" }}
                >
                  GIRAFFE
                </Typography>
                <Typography
                  sx={{ ...sxType, fontSize: "16px", ml: 2, opacity: 0.9, textAlign: "right" }}
                >
                  600ALC
                </Typography>
              </Box>
              <Box
                component="img"
                src={giraffe}
                sx={{
                  width: "100%",
                }}
              />
            </Box>
            <Box sx={{ ...sxColumn, maxWidth: "150px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  alignItems: "end",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ ...sxTypeMedium, fontSize: "16px", mr: 2, opacity: 0.8, textAlign: "left" }}
                >
                  OWL
                </Typography>
                <Typography
                  sx={{ ...sxType, fontSize: "16px", ml: 2, opacity: 0.9, textAlign: "right" }}
                >
                  700ALC
                </Typography>
              </Box>
              <Box
                component="img"
                src={owl}
                sx={{
                  width: "100%",
                }}
              />
            </Box>{" "}
            <Box sx={{ ...sxColumn, maxWidth: "150px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  alignItems: "end",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ ...sxTypeMedium, fontSize: "16px", mr: 2, opacity: 0.8, textAlign: "left" }}
                >
                  MONKEY
                </Typography>
                <Typography
                  sx={{ ...sxType, fontSize: "16px", ml: 2, opacity: 0.9, textAlign: "right" }}
                >
                  800ALC
                </Typography>
              </Box>
              <Box
                component="img"
                src={monkey}
                sx={{
                  width: "100%",
                }}
              />
            </Box>{" "}
            <Box sx={{ ...sxColumn, maxWidth: "150px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  alignItems: "end",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ ...sxTypeMedium, fontSize: "16px", mr: 2, opacity: 0.8, textAlign: "left" }}
                >
                  DEER
                </Typography>
                <Typography
                  sx={{ ...sxType, fontSize: "16px", ml: 2, opacity: 0.9, textAlign: "right" }}
                >
                  900ALC
                </Typography>
              </Box>
              <Box
                component="img"
                src={deer}
                sx={{
                  width: "100%",
                }}
              />
            </Box>{" "}
            <Box sx={{ ...sxColumn, maxWidth: "150px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  alignItems: "end",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ ...sxTypeMedium, fontSize: "16px", mr: 2, opacity: 0.8, textAlign: "left" }}
                >
                  RABBIT
                </Typography>
                <Typography
                  sx={{ ...sxType, fontSize: "16px", ml: 2, opacity: 0.9, textAlign: "right" }}
                >
                  1000ALC
                </Typography>
              </Box>
              <Box
                component="img"
                src={rabbit}
                sx={{
                  width: "100%",
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* DISCORD SECTION */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            py: 4,
            px: 2,
            background:
              "linear-gradient(254.43deg, #A558F2 -5.66%, #1D1D23 23.06%, #1C1C1E 73.74%, #5865F2 100.04%)",
          }}
        >
          <Button
            href="https://discord.gg/rQwYN2maph"
            target="_blank"
            sx={{
              border: "4px solid white",
              borderRadius: 0,
              height: "auto",
              py: 2,
              px: 5,
              mt: 4,
            }}
          >
            <Typography
              variant="h4"
              component="span"
              sx={{
                fontSize: { xs: "18px", sm: "20px", md: "36px" },
                color: "#fff",
                opacity: 0.87,
              }}
            >
              Join our discord server
            </Typography>
          </Button>
          <Typography variant="subtitle1" sx={{ mt: 3, mb: 4, color: "#fff", opacity: 0.87 }}>
            Want to know our team and have fun conversations? come on in!
          </Typography>
        </Box>
      </Box>
    </>
  );
}
