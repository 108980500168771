import React, { useState } from "react";
import { Box } from "@mui/material";
import withRoot from "../withRoot";
import Nav from "./views/Nav";
import Shuffle from "./views/Shuffle";
import Snackbar from "./../components/Snackbar";
import SnackbarError from "./../components/SnackbarError";
import { connectToMyAlgo } from "./utils/algoConnect";
import { Route, Routes } from "react-router-dom";

// const price = 1000000; // 1 ALGO

function MarketPlace({ socket }) {
  const [algoWallet, setAlgoWallet] = useState({ address: "", name: "" });
  // const [nfts, setNfts] = useState([]);
  const [snack, setSnack] = useState({ open: false, message: "" });

  const onTxnSuccess = (assetId) => {
    setTimeout(() => {
      setSnack({ open: false });
    }, 10000);
    setSnack({
      open: true,
      message: `Congrats! You've successfully purchased Algopard #${assetId}`,
    });
  };

  const promptSignIn = () => {
    const timer = setTimeout(() => {
      setSnack({ openError: false });
    }, 8000);

    setSnack({
      timer: timer,
      openError: true,
      message: "Please connect your wallet to continue.",
    });
  };

  const onTxnFail = () => {
    setSnack({
      openError: true,
      message:
        "There was an error completing the transaction. \n\nIf it has been 10mins the transaction timed out. Otherwise, you may not have enough funds in your wallet. \n\nPlease contact Algopard_dev in discord if you're still having trouble.",
    });
    setTimeout(() => {
      setSnack({ openError: false });
    }, 15000);
  };
  const dbError = () => {
    setTimeout(() => {
      setSnack({ openError: false });
    }, 8000);
    setSnack({
      openError: true,
      message:
        "We can't connect to the server at this time. Please contact Algopard_dev in discord",
    });
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const nftRes = await getNftInventory();
  //     setNfts(nftRes);
  //   };
  //   fetchData().catch(console.error);
  // }, []);

  return (
    <>
      <Nav
        algoWallet={algoWallet}
        setAlgoWallet={setAlgoWallet}
        connectToMyAlgo={connectToMyAlgo}
      />
      <Box
        component="section"
        sx={{
          display: "flex",
          bgcolor: "#17171A",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        {/* <Sidebar /> */}
        <Box
          component="section"
          sx={{
            display: "flex",
            pt: "102px",
            pb: "52px",
            width: "100%",
          }}
        >
          <Routes>
            {/* <Route
              path="/"
              element={
                <Collection
                  algoWallet={algoWallet}
                  nfts={nfts}
                  onTxnSuccess={onTxnSuccess}
                  promptSignIn={promptSignIn}
                />
              }
            /> */}
            <Route
              path="/"
              element={
                <Shuffle
                  algoWallet={algoWallet}
                  onTxnFail={onTxnFail}
                  onTxnSuccess={onTxnSuccess}
                  promptSignIn={promptSignIn}
                  dbError={dbError}
                  socket={socket}
                />
              }
            />
          </Routes>
        </Box>
        <Snackbar
          open={snack.open}
          closeFunc={() => {
            clearTimeout(snack.timer);
            setSnack({});
          }}
          message={snack.message}
        />
        <SnackbarError
          open={snack.openError}
          closeFunc={() => {
            clearTimeout(snack.timer);
            setSnack({});
          }}
          message={snack.message}
        />
      </Box>
    </>
  );
}

export default withRoot(MarketPlace);
