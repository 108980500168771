import React from "react";

export function InfoBox({ children }) {
  return (
    <>
      <div className="info-box" sx={{ mx: 2 }}>
        {children}
      </div>
    </>
  );
}
