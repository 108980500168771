import algosdk from "algosdk";
import { algodClient, connection } from "./connections";
import { accounts } from "./accounts.js";
import { signTxn } from "./api";

export async function transaction({
  animation,
  assetId,
  customer,
  price,
  onTxnSuccess,
  onTxnFail,
}) {
  try {
    const creator = accounts.creator;
    const suggestedParams = await algodClient.getTransactionParams().do();

    // USER OPT-IN TO RECEIVE ASSET
    const optTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      suggestedParams,
      from: customer,
      to: customer,
      assetIndex: assetId,
      amount: 0,
    });

    // CREATOR PAYMENT TRANSACTION
    const payTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      suggestedParams,
      from: customer,
      to: creator.addr,
      amount: price - price * 0.15,
    });

    // DEV FEE TRANSACTION
    const devFeeTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      suggestedParams,
      from: customer,
      to: "U5RDEH7AEDTAGDG2FZ4ODWZS27MSGW4BLUJPRB7B76UQHSNKM56MREWM2A",
      amount: price * 0.15,
    });

    const txns = [optTxn, payTxn, devFeeTxn];
    const txnGroupId = algosdk.assignGroupID([...txns]);
    console.log(txnGroupId, "group id");

    // ASA TRANSFER
    const asaTxn = await signTxn(
      assetId,
      customer,
      txnGroupId,
      suggestedParams
    );

    // SIGN TXNS
    const signedUserTxns = await connection.signTransaction(
      txns.map((txn) => {
        console.log(txn.toByte());
        return txn.toByte();
      })
    );

    const signedAsaTxn = new Uint8Array(asaTxn.data);
    console.log(signedAsaTxn, "SIGNED ASA TXN DATA");
    // const signedAsaTxn = asaTxn.signTxn(creator.sk);

    const signedTxns = [...signedUserTxns.map((txn) => txn.blob), signedAsaTxn];

    // SUBMIT ATOMIC TRANSFER
    const { txId } = await algodClient.sendRawTransaction(signedTxns).do();

    animation.start(); // start animation while waiting for confirmation
    // CONFIRM TXN SUCCESS
    await algosdk.waitForConfirmation(algodClient, txId, 4);
    onTxnSuccess(assetId);
  } catch (err) {
    onTxnFail();
    console.error(err);
  }
}
