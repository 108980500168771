import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { accounts } from "../utils/accounts";
import { transaction } from "../utils/txn";
import { getAssetId, cancelShuffleTxn, completeTxn } from "../utils/api";
import { NftImages } from "./NftImages";
import { animateTiles } from "../utils/animation";
import { ClosedShuffle } from "./ClosedShuffle";
import { LiveShuffle } from "./LiveShuffle";
import confetti from "canvas-confetti";

const animation = animateTiles();

export default function Shuffle({
  algoWallet,
  promptSignIn,
  onTxnFail,
  onTxnSuccess,
  dbError,
  socket,
}) {
  const [shuffle, setShuffle] = useState(false);
  const [disableButton, setDisabled] = useState(false);

  // "U5RDEH7AEDTAGDG2FZ4ODWZS27MSGW4BLUJPRB7B76UQHSNKM56MREWM2A"
  // "PARDGF6H4Z2GNRFG2DNN4TNOWNHU3EMH6OLHZXPVEDN74YNE7LHVIIFKH4"
  useEffect(() => {
    socket.emit("user", {
      creatorAddr: accounts.creator.addr,
      id: socket.id,
    });
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
    socket.on("shuffle-update", (shuffle) => {
      console.log(shuffle, "shuffle data");
      setShuffle(shuffle);
    });
  }, [socket]);

  useEffect(() => {
    if (algoWallet.address) {
      socket.emit("wallet-connect", algoWallet.address);
    }
  }, [algoWallet, socket]);

  // On txn success, remove the asset from reservedAssets in db
  const handleTxnSuccess = async (assetId, timer, shuffleId) => {
    const successRes = await completeTxn(assetId, timer, shuffleId);
    socket.emit("txn-success");
    animation.stop();
    confetti({
      particleCount: 60,
      startVelocity: 25,
      spread: 360,
      ticks: 250,
    });
    confetti({
      particleCount: 60,
      angle: 60,
      spread: 75,
      origin: { x: 0 },
      ticks: 250,
    });
    // and launch a few from the right edge
    confetti({
      particleCount: 60,
      angle: 120,
      spread: 75,
      origin: { x: 1 },
      ticks: 250,
    });
    console.log(successRes, "successres");
    onTxnSuccess(assetId);
    setDisabled(false);
  };

  // on txn fail, move the asset from reserved to availableAssets
  const handleTxnFail = async (assetId, timer, shuffleId) => {
    socket.emit("canceled-asset");
    const cancelres = await cancelShuffleTxn(assetId, timer, shuffleId);
    animation.stop();
    console.log(cancelres, "cancelres");
    onTxnFail();
    setDisabled(false);
  };

  const onShuffleClick = async () => {
    try {
      if (!algoWallet.address) {
        promptSignIn();
        return;
      }
      if (!shuffle._id) {
        dbError();
        return;
      }
      setDisabled(true); // disable button
      animation.start();
      // Reserve an assetID by calling the server
      const res = await getAssetId(shuffle._id);
      console.log(res, "getAssetId res");
      socket.emit("reserved-asset", res.data.assetId);

      animation.stop();
      // Create the transaction w reserved assetID
      transaction({
        animation: animation,
        assetId: res.data.assetId,
        customer: algoWallet.address,
        price: shuffle.entryPrice * 1000000,
        onTxnSuccess: () => handleTxnSuccess(res.data.assetId, res.data.timer, shuffle._id),
        onTxnFail: () => handleTxnFail(res.data.assetId, res.data.timer, shuffle._id),
      });
    } catch (e) {
      console.error(e);
      animation.stop();
      setDisabled(false);
      onTxnFail();
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "70px",
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 2,
          backgroundColor: "#000",
          opacity: 0.4,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "70px",
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 3,
          display: "flex",
          flexFlow: "column wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!shuffle ? (
          <></>
        ) : !shuffle.isOpen || shuffle.availableAssets.length === 0 ? (
          <ClosedShuffle />
        ) : (
          <LiveShuffle
            shuffle={shuffle}
            disableButton={disableButton}
            onShuffleClick={onShuffleClick}
          />
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "70px",
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 1,
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <NftImages />
      </Box>
    </>
  );
}
