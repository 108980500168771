async function runLoop(count) {
  const promise = new Promise(function (resolve, reject) {
    for (let i = 0; i <= 24; i++) {
      const el = document.getElementById(`${i}`);
      el.style.opacity = "0.35";

      const time = Math.random() * 3000;

      setTimeout(() => {
        el.style.opacity = "1";
        i === 24 && resolve(count + 1);
      }, time);
    }
  });
  return promise;
}

export function animateTiles() {
  let run;
  let count = 0;
  const start = async () => {
    run = true;
    while (run) {
      let loop = await runLoop(count);
      count = loop;
      if (count === 60) run = false;
    }
  };
  const stop = () => {
    run = false;
    console.log("stop animation ran");
  };
  return { start, stop };
}
