import { login } from "../../marketplace/utils/api";

export async function checkAuth(creatorAddr) {
  try {
    console.log(creatorAddr, "auth hook");
    // if (creatorAddr) {
    //   return creatorAddr;
    // }
    const auth = await login(creatorAddr);
    console.log(auth.data, "AUTH LOGIN RES");
    return auth.data;
  } catch (e) {
    console.error(e);
  }
}
