import axios from "axios";
const env = process.env.NODE_ENV;
// const env = "prod";

const apiUrl =
  env === "development"
    ? process.env.REACT_APP_API_DEV
    : process.env.REACT_APP_API_PROD;

export async function getAssetId(creatorAddr) {
  try {
    return await axios.post(`${apiUrl}/shuffle/reserve-asset`, {
      addr: creatorAddr,
    });
  } catch (err) {
    console.error(err);
  }
}

export async function cancelShuffleTxn(assetId, timer, address) {
  try {
    return await axios.post(`${apiUrl}/shuffle/cancel`, {
      addr: address,
      assetId: assetId,
      timer: timer,
    });
  } catch (err) {
    console.error(err);
  }
}

export async function completeTxn(assetId, timer, address) {
  try {
    return await axios.post(`${apiUrl}/shuffle/complete-txn`, {
      addr: address,
      assetId: assetId,
      timer: timer,
    });
  } catch (err) {
    console.error(err);
  }
}

export async function signTxn(assetId, customer, groupId, suggestedParams) {
  try {
    return await axios.post(`${apiUrl}/txn/sign`, {
      customer: customer,
      assetId: assetId,
      groupId: groupId,
      suggestedParams: suggestedParams,
    });
  } catch (err) {
    console.error(err);
  }
}

export async function login(address) {
  try {
    return await axios.post(`${apiUrl}/admin/login`, {
      addr: address,
    });
  } catch (err) {
    console.error(err);
  }
}

export async function updateShuffle(address, updateFields, token) {
  try {
    return await axios.post(
      `${apiUrl}/admin/update-shuffle`,
      {
        addr: address,
        fields: updateFields,
      },
      { headers: { token } }
    );
  } catch (err) {
    console.error(err);
  }
}

export async function setAvailableAssets(
  address,
  numAssets,
  price = 15,
  token
) {
  try {
    return await axios.post(
      `${apiUrl}/admin/update-assets`,
      {
        addr: address,
        numAssets: Number(numAssets),
        price: Number(price),
      },
      { headers: { token } }
    );
  } catch (err) {
    console.error(err);
  }
}

export async function scheduleShuffle(
  address,
  time,
  closeTime,
  weekDay,
  closeWeekDay,
  token
) {
  try {
    return await axios.post(
      `${apiUrl}/admin/schedule-shuffle`,
      {
        addr: address,
        min: time.minute(),
        hour: time.hour(),
        closeMin: closeTime.minute(),
        closeHour: closeTime.hour(),
        weekDay: weekDay,
        closeWeekDay: closeWeekDay,
      },
      { headers: { token } }
    );
  } catch (err) {
    console.error(err);
  }
}
