import React from "react";
import { Typography, Box } from "@mui/material";
import cryImg from "./../images/cry-pard.jpg";

const sxType = {
  fontFamily: "Work Sans",
  fontWeight: 400,
  fontSize: "16px",
  color: "#fff",
  opacity: 0.9,
  lineHeight: "19px",
  textAlign: "center",
  mt: 2,
};

export function ClosedShuffle() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#1c1c21",
          borderRadius: "7px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          py: 5,
          px: 2,
          width: "90vw",
          minWidth: "280px",
          maxWidth: "700px",
        }}
      >
        <Box
          component="img"
          src={cryImg}
          sx={{ width: "100%", maxWidth: "270px", mb: 2, borderRadius: "7px" }}
        />
        <Typography sx={sxType}>Ohps! Looks like this shuffle is closed.</Typography>
      </Box>
    </>
  );
}
