import React from "react";
import { Box } from "@mui/material";

export function Loading() {
  return (
    <Box
      className="suspense-img"
      sx={{
        // width: "calc((100vw - 20px) / 5)",
        // height: "calc((100vh - 90px) / 5)",
        backgroundImage: `url("/assets/loading.webp")`,
        // backgroundColor: "#262627",
        backgroundSize: "cover",
        backgroundPosition: "center",
        margin: "1px",
        borderRadius: "7px",
      }}
    />
  );
}
