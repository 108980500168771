import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../../components/AppBar";
import Toolbar from "../../components/Toolbar";
import logo from "./../images/algopard.gif";

const linkStyle = {
  fontSize: 14,
  color: "common.white",
  fontWeight: 400,
  letterSpacing: "0.08em",
  ml: 3,
  textTransform: "uppercase",
};

export default function Nav() {
  const [mobileNav, setMobileNav] = useState(false);
  const [isMenuActive, toggleMenu] = useState(false);

  const checkWidth = () => {
    setMobileNav(window.innerWidth <= 850);
  };

  useEffect(() => {
    setMobileNav(window.innerWidth <= 850);
    window.addEventListener("resize", checkWidth);
  }, []);

  return (
    <div>
      <AppBar position="fixed" sx={{ backgroundColor: "#020816" }}>
        <Toolbar
          style={{ paddingLeft: 0 }}
          sx={{
            justifyContent: "space-between",
          }}
        >
          {/* <Box
            sx={{
              height: 70,
              width: "24px",
              backgroundColor: "#BCBCBC",
              ml: 0,
            }}
          /> */}
          <Link sx={{ ml: 0, height: "70px" }} underline="none" color="inherit" href="#">
            <Box component="img" src={logo} alt="algopard logo" sx={{ height: 70 }} />
          </Link>
          <Box sx={{ flex: 1 }} />

          {mobileNav && (
            <Box
              component="img"
              src="/assets/hamburger-menu.svg"
              onClick={() => toggleMenu(!isMenuActive)}
              alt="menu"
              sx={{
                width: "42px",
                height: "42px",
              }}
            />
          )}

          <Box
            sx={
              mobileNav
                ? isMenuActive
                  ? {
                      position: "absolute",
                      bgcolor: "#011106",
                      display: "flex",
                      flexDirection: "column",
                      height: "200px",
                      justifyContent: "space-around",
                      top: 70,
                      left: 0,
                      right: 0,
                    }
                  : { display: "none" }
                : { flex: 1, display: "flex", justifyContent: "flex-end" }
            }
          >
            <Link
              color="inherit"
              underline="none"
              href="/#roadmap"
              onClick={() => toggleMenu(false)}
              sx={linkStyle}
            >
              {"Roadmap"}
            </Link>
            <Link
              underline="none"
              href="/#distribution"
              onClick={() => toggleMenu(false)}
              sx={{ ...linkStyle }}
            >
              {"Distribution"}
            </Link>
            <Link
              underline="none"
              href="/#about"
              onClick={() => toggleMenu(false)}
              sx={{ ...linkStyle }}
            >
              {"About"}
            </Link>
            <Link
              underline="none"
              href="/#collection"
              onClick={() => toggleMenu(false)}
              sx={{ ...linkStyle }}
            >
              {"Collection"}
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}
