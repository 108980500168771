import React, { useState } from "react";
import Collection from "./views/Collection";
import ProductSmokingHero from "./views/ProductSmokingHero";
import AppFooter from "./views/AppFooter";
import ProductHero from "./views/ProductHero";
import RoadMap from "./views/RoadMap";
import Distribution from "./views/Distribution";
import About from "./views/About";
import ProductCTA from "./views/ProductCTA";
import withRoot from "../withRoot";
import { Banner } from "./Banner";

function Home() {
  const [open, setOpen] = useState(true);
  return (
    <>
      <ProductHero />
      <RoadMap />
      <Distribution />
      <About />
      <Collection />
      <ProductCTA />
      <ProductSmokingHero />
      <AppFooter />

      {open && <Banner closeFunc={() => setOpen(false)} />}
    </>
  );
}

export default withRoot(Home);
