import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./home/App.js";
import MarketPlace from "./marketplace/MarketPlace.js";
import Admin from "./admin/Admin";
import { io } from "socket.io-client";
import "./index.css";

const env = process.env.NODE_ENV;

const socket = io(
  env === "development"
    ? process.env.REACT_APP_API_DEV
    : process.env.REACT_APP_API_PROD
);

socket.on("connect", () => {
  console.log("websocket connected! ID: ", socket.id);
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />} />
        <Route path="marketplace/*" element={<MarketPlace socket={socket} />} />
        <Route path="admin" element={<Admin socket={socket} />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
