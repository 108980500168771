import React from "react";
import btnGif from "marketplace/images/rainbow.gif";

const bgStyle = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "180px",
  height: "40px",
  borderRadius: "3px",
  marginTop: "2em",
  cursor: "pointer",
};

const textStyle = {
  zIndex: 2,
  textAlign: "center",
  fontFamily: "Work Sans",
  fontWeight: 500,
  lineHeight: 0,
  color: "#fff",
};

const imgStyle = {
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: "3px",
  background: `url(${btnGif}), #858592`,
  backgroundSize: "cover",
  filter: "blur(1px)",
  backgroundBlendMode: "soft-light",
};

export function ShuffleButton({ onClick, disabled }) {
  return (
    <div
      onClick={onClick}
      className={disabled ? "disabled" : "shuffle-btn"}
      style={bgStyle}
    >
      <div style={textStyle}>JOIN THE SHUFFLE</div>
      <div style={imgStyle} />
    </div>
  );
}
